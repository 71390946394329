<template>
  <div>
    <vx-card actionable class="cardx">
      <vs-row>
        <vs-col justify="left" align="left" style="padding-left: 0%" vs-w="9">
          <h2 style="padding-top: 1%; padding-bottom: 3%; padding-left: 0%">
            Enrollements Turn Around Time
          </h2>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <p><b>TAT:</b></p>
          &nbsp;&nbsp;&nbsp;
          <v-select
            placeholder="TAT"
            style="z-index: 1000"
            class="w-full"
            v-model="selected_tat"
            :options="tat_options"
            label="TAT"
          ></v-select>
        </vs-col>
        <vs-col vs-w="1">
          <vs-button
            style="margin-top: 11%"
            radius
            color="dark"
            type="gradient"
            icon="refresh"
            @click="refresh()"
          ></vs-button>
        </vs-col>
      </vs-row>
      <p style="color: black; display: inline; padding-bottom: 2%">
        <b>Enrollement Count:</b> {{ get_selected_data.length }}
      </p>
      <p
        style="
          color: black;
          display: inline;
          padding-left: 2%;
          padding-bottom: 2%;
        "
      >
        <b>Sources Count :</b> {{ this.source_count }}
      </p>
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <p><b>Year:</b></p>
          &nbsp;&nbsp;&nbsp;
          <v-select
            placeholder="Year"
            style="z-index: 1000; margin-top: 3%"
            class="w-full"
            v-model="selected_year"
            :options="years"
            label="Year"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <p><b>Month:</b></p>
          &nbsp;&nbsp;&nbsp;
          <v-select
            placeholder="Months"
            style="z-index: 1000; margin-top: 3%"
            class="w-full"
            v-model="selected_month"
            :options="months"
            label="Months"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <p><b>Course:</b></p>
          &nbsp;&nbsp;&nbsp;
          <v-select
            placeholder="Courses"
            style="z-index: 1000; margin-top: 3%"
            class="w-full"
            v-model="selected_course"
            :options="courses"
            label="Courses"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <p><b>Source:</b></p>
          &nbsp;&nbsp;&nbsp;
          <v-select
            placeholder="Sources"
            style="z-index: 1000; margin-top: 3%"
            class="w-full"
            v-model="selected_source"
            :options="sorted_sources"
            label="Sources"
          ></v-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
          v-if="show_FAM"
        >
          <p><b>FAM:</b></p>
          &nbsp;&nbsp;&nbsp;
          <vs-input placeholder="Search" v-model="fam_length" />
          <vs-button
            color="dark"
            type="gradient"
            icon="search"
            @click="getEnrollmentSourceReports(fam_length)"
          ></vs-button>
        </vs-col>
        <vs-col
          v-if="show_LAM"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <p><b>LAM:</b></p>
          &nbsp;&nbsp;&nbsp;
          <vs-input placeholder="Search" v-model="lam_length" />
          <vs-button
            color="dark"
            type="gradient"
            icon="search"
            @click="getEnrollmentSourceReports(lam_length)"
          ></vs-button>
        </vs-col>
        <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button style="margin-top: 5%" color="dark" @click="getData"
            >Go</vs-button
          >
        </vs-col> -->
      </vs-row>
      <br />
      <div align="right">
        <div style="display: inline">
          <vue-json-to-csv
            :json-data="enrollment_source"
            :csv-title="
              'enrollementreport' +
              '-' +
              this.selected_year +
              '-' +
              this.selected_month
            "
          >
            <vs-button
              @click="getEnrollmentReportData()"
              type="filled"
              color="dark"
            >
              Enrollement Export CSV
            </vs-button>
          </vue-json-to-csv>
        </div>
        <div style="display: inline; margin-left: 1%">
          <vue-json-to-csv
            :json-data="sources_raw_json_data"
            :csv-title="
              'sourcereport' +
              '-' +
              this.selected_year +
              '-' +
              this.selected_month
            "
          >
            <vs-button
              @click="getsourceReportData()"
              type="filled"
              color="dark"
            >
              Source Export CSV
            </vs-button>
          </vue-json-to-csv>
        </div>
      </div>
      <br />
      <vs-table
        max-items="10"
        pagination
        :data="get_selected_data"
        v-if="visible"
      >
        <template slot="thead" class="exc">
          <vs-th>Can-ID</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Course</vs-th>
          <vs-th>Enrollement</vs-th>
          <vs-th v-if="show_FAM">FAM TAT</vs-th>
          <vs-th v-if="show_LAM">LAM TAT</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(item, indextr) in data">
            <vs-td>
              <div>{{ item.identity }}</div>
            </vs-td>
            <vs-td>
              <div>{{ item.person_name }}</div>
            </vs-td>
            <vs-td>
              <div>{{ item.course }}</div>
            </vs-td>
            <vs-td>
              <div>{{ customFormatter(item.enrollment_date) }}</div>
            </vs-td>
            <vs-td v-if="show_FAM">
              <div>{{ item.tat }} ({{ item.FAM_source }})</div>
              <!--({{getFAMSource(item.sources)}})-->
            </vs-td>
            <vs-td v-if="show_LAM">
              <div>
                {{ getLAMTAT(item.sources, item.enrollment_date) }} ({{
                  item.LAM_source
                }})
              </div>
              <!--({{getLAMSource(item.sources)}})-->
            </vs-td>
            <template class="expand-user" slot="expand">
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-w="12"
                >
                  <div style="width: 100%">
                    <vs-table :data="sortSourcesTime(item.sources)">
                      <template slot="thead">
                        <vs-th> Date </vs-th>
                        <vs-th> Source </vs-th>
                        <vs-th> Source Details </vs-th>
                        <vs-th> Type </vs-th>
                        <vs-th> Table </vs-th>
                        <vs-th> Source TAT </vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].table">
                            {{ customFormatter(data[indextr].time) }}
                          </vs-td>
                          <vs-td :data="data[indextr].name">
                            {{ data[indextr].name }}
                          </vs-td>
                          <vs-td :data="data[indextr].details">
                            {{ data[indextr].details }}
                          </vs-td>
                          <vs-td :data="data[indextr].net_enquiry_type">
                            {{ data[indextr].net_enquiry_type }}
                          </vs-td>
                          <vs-td :data="data[indextr].table">
                            {{ data[indextr].table }}
                          </vs-td>
                          <vs-td :data="data[indextr].time">
                            {{
                              getSourceTAT(
                                data[indextr].time,
                                item.enrollment_date
                              )
                            }}
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                  </div>
                </vs-col>
              </vs-row>
            </template>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <vx-card
      v-if="show_bar_chart"
      style="margin-top: 2%"
      actionable
      class="cardx"
    >
    <h4 v-if="lam_title != ''">{{lam_title}}: </h4>
      <vue-apex-charts
        ref="apexChart"
        type="bar"
        :options="options"
        :series="options.sources"
      ></vue-apex-charts>
    </vx-card>
    <vx-card
      v-if="show_fam_graph"
      style="margin-top: 2%"
      actionable
      class="cardx"
    >
    <h4>FAM : </h4>
      <vue-apex-charts
        ref="apexChart"
        type="bar"
        :options="fam_graph_options"
        :series="fam_graph_options.sources"
      ></vue-apex-charts>
    </vx-card>
  </div>
</template>
<script>
import axios from "axios";
import constants from "../../constants.json";
import moment from "moment";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  components: {
    VueJsonToCsv,
    "v-select": vSelect,
    VueApexCharts,
  },
  data() {
    return {
      lam_title: "",
      show_fam_graph: true,
      fam_graph_options: {},
      enrollment_source: [],
      source_report_data: [],
      enrollements_raw_json_data: [],
      sources_raw_json_data: [],
      tat_options: ["FAM", "LAM", "All"],
      selected_tat: "All",
      show_FAM: true,
      show_LAM: true,
      FAM_sources_list: [],
      LAM_sources_list: [],
      FAM_count: 0,
      LAM_count: 0,
      source_count: 0,
      show_bar_chart: true,
      options: {
        series: [
          {
            data: [],
          },
        ],
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            vertical: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },

      lam_length: null,
      fam_length: null,
      get_fam_data: false,
      get_lam_data: false,
      sorted_sources: [],
      sources: ["All"],
      selected_source: "All",
      selected_course: "All",
      courses: ["All"],
      visible: false,
      get_selected_data: [],
      selected_year: 2022,
      selected_month: "January",
      selected_month_number: null,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      years: [],
      tat_data: [],
    };
  },
  mounted() {
    this.getYears();
    this.getEnrollmentSourceReports();
  },
  watch: {
    sorted_sources(val) {
      console.log(val, "sorted_sources@@");
    },
    selected_tat(val) {
      this.selected_tat = val;
      if (val === "FAM") {
        this.show_bar_chart = false;
        this.show_fam_graph = true;
        this.selected_source = "All";
        this.show_FAM = true;
        this.show_LAM = false;
        this.getEnrollmentSourceReports();
      }
      if (val === "LAM") {
        this.show_fam_graph = false;
        this.show_bar_chart = true;
        this.selected_source = "All";
        this.show_LAM = true;
        this.show_FAM = false;
        this.getEnrollmentSourceReports();
      }
      if (val === "All") {
        this.show_fam_graph = true;
        this.show_bar_chart = true;
        this.selected_source = "All";
        this.show_LAM = true;
        this.show_FAM = true;
        this.getEnrollmentSourceReports();
      }
    },
    get_selected_data(val) {
      for (let i = 0; i < val.length; i++) {
        //To get FAM source
        const element = val[i];
        const sorted_sources_time = _.sortBy(element.sources, "time");
        element.FAM_source = sorted_sources_time[0].name;

        //To get LAM source
        let last_element = sorted_sources_time.length - 1;
        element.LAM_source = sorted_sources_time[last_element].name;
      }
    },
    source_count(val) {
      console.log(val, "source_count");
      this.source_count = val;
    },
    fam_length(val) {
      this.show_bar_chart = true;
      if (this.lam_length != null) {
        this.get_lam_data = false;
      }
      if (this.fam_length != null) {
        this.get_fam_data = true;
      }
      if (this.fam_length == null || this.fam_length == "") {
        this.get_fam_data = false;
      }
      this.fam_length = val;
    },
    lam_length(val) {
      this.show_bar_chart = true;
      if (this.fam_length != null) {
        this.get_fam_data = false;
      }
      if (this.lam_length != null) {
        this.get_lam_data = true;
      }
      if (this.lam_length == null || this.lam_length == "") {
        this.get_lam_data = false;
      }
      this.lam_length = val;
    },
    selected_course(val) {
      this.show_bar_chart = true;
      if(this.selected_tat === "FAM"){
      this.show_fam_graph = true;
      }
      this.selected_course = val;
      this.getEnrollmentSourceReports();
    },
    selected_source(val) {
      this.show_bar_chart = true;
      if(this.selected_tat === "FAM"){
      this.show_fam_graph = true;
      }
      this.selected_source = val;
      this.getEnrollmentSourceReports();
    },
    selected_month(val) {
      this.show_bar_chart = true;
      if(this.selected_tat === "FAM"){
      this.show_fam_graph = true;
      }
      this.getMonthNumber(val);
      this.getEnrollmentSourceReports();
    },
    selected_year(val) {
      this.show_bar_chart = true;
      if(this.selected_tat === "FAM"){
      this.show_fam_graph = true;
      }
      this.selected_year = val;
      this.getEnrollmentSourceReports();
    },
  },
  methods: {
    getEnrollmentReportData() {
      let result = JSON.parse(JSON.stringify(this.get_selected_data));
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        element.LAM_tat = this.getLAMTAT(
          element.sources,
          element.enrollment_date
        );
        element.enrollment_date = this.customFormatter(element.enrollment_date);
        element.FAM_tat = element.tat;
      }
      for (let i in result) {
        let obj = result[i];
        delete obj["sources"];
      }
      this.enrollment_source = result;
    },
    getsourceReportData() {
      console.log("sources data", this.get_selected_data);
      let result = JSON.parse(JSON.stringify(this.get_selected_data));
      for (let i = 0; i < result.length; i++) {
        let element = result[i];
        for (let i = 0; i < element.sources.length; i++) {
          let item = element.sources[i];
          item.identity = element.identity;
          item.FAM_source = element.FAM_source;
          item.FAM_tat = element.tat;
          item.LAM_source = element.LAM_source;
          item.LAM_tat = this.getLAMTAT(
            element.sources,
            element.enrollment_date
          );
          item.course = element.course;
          item.enrollment_date = this.customFormatter(element.enrollment_date);
          item.person_name = element.person_name;
          item.tat = element.tat;
          let source_tat = this.getSourceTAT(
            item.time,
            element.enrollment_date
          );
          item.source_tat = source_tat;
          item.time = this.customFormatter(item.time);
        }
        element = element.sources[i];
      }
      let reduce_array = result.map((item) => item.sources);
      reduce_array = _.flatten(reduce_array);
      console.log("reduce_array324", reduce_array);
      this.sources_raw_json_data = reduce_array;
    },
    getIndividualFAMSourceCount(sources_data) {
      console.log("sources_data+++", sources_data);
      let FAM_sources_list = [];
      for (let i = 0; i < sources_data.length; i++) {
        const element = sources_data[i];
        const sorted_sources_time = _.sortBy(element.sources, "time");
        if (this.selected_source === sorted_sources_time[0].name) {
          FAM_sources_list.push(sorted_sources_time);
        }
      }
      console.log("sources_data", FAM_sources_list.length, FAM_sources_list);
      this.source_count = FAM_sources_list.length;
    },
    getFAMSource(data) {
      const sorted_sources_time = _.sortBy(data, "time");
      this.FAM_source = sorted_sources_time[0].name;
      return this.FAM_source;
    },
    getLAMSource(data) {
      const sorted_sources_time = _.sortBy(data, "time");
      let last_element = sorted_sources_time.length - 1;
      this.LAM_source = sorted_sources_time[last_element].name;
      return this.LAM_source;
    },
    refresh() {
      this.selected_tat = "All";
      this.show_bar_chart = true;
      this.selected_year = 2022;
      this.selected_month = "January";
      this.selected_month_number = null;
      this.selected_source = "All";
      this.selected_course = "All";
      this.lam_length = null;
      this.fam_length = null;
      this.get_fam_data = false;
      this.get_lam_data = false;
      this.getEnrollmentSourceReports();
    },
    getRequiredFAMData(FAM_limit) {
      let courses_data = [];
      console.log("ertry", FAM_limit);
      for (let i = 0; i < this.get_selected_data.length; i++) {
        const element = this.get_selected_data[i];
        if (element.tat <= FAM_limit) {
          courses_data.push(element);
        }
      }
      this.get_selected_data = courses_data;
    },
    getRequiredLAMData(lam_length) {
      console.log(lam_length);
      let lam_data = [];
      for (let i = 0; i < this.get_selected_data.length; i++) {
        const element = this.get_selected_data[i];
        const sorted_sources_time = _.sortBy(element.sources, "time");
        let start_date =
          sorted_sources_time[sorted_sources_time.length - 1].time;
        let tat_start_date = moment.unix(start_date).format("YYYY-MM-DD");
        let tat_end_date = moment
          .unix(element.enrollment_date)
          .format("YYYY-MM-DD");
        const a = new Date(tat_start_date),
          b = new Date(tat_end_date),
          days_difference = this.dateDiffInDays(a, b);
        if (days_difference <= lam_length) {
          lam_data.push(element);
        }
      }
      this.get_selected_data = lam_data;
      return this.get_selected_data;
    },
    getLAMTAT(data, end_date) {
      const sorted_sources_time = _.sortBy(data, "time");
      let start_date = sorted_sources_time[sorted_sources_time.length - 1].time;
      let tat_start_date = moment.unix(start_date).format("YYYY-MM-DD");
      let tat_end_date = moment.unix(end_date).format("YYYY-MM-DD");
      const a = new Date(tat_start_date),
        b = new Date(tat_end_date),
        days_difference = this.dateDiffInDays(a, b);
      return days_difference;
    },
    getSourceTAT(start_date, end_date) {
      let tat_start_date = moment.unix(start_date).format("YYYY-MM-DD");
      let tat_end_date = moment.unix(end_date).format("YYYY-MM-DD");
      const a = new Date(tat_start_date),
        b = new Date(tat_end_date),
        days_difference = this.dateDiffInDays(a, b);
      return days_difference;
    },
    dateDiffInDays(a, b) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    },

    getTatBasedOnCourse(course) {
      let courses_data = [];
      for (let i = 0; i < this.get_selected_data.length; i++) {
        const element = this.get_selected_data[i];
        if (course === element.course) {
          courses_data.push(element);
        }
      }
      this.get_selected_data = courses_data;
    },
    getTatBasedOnSource(source) {
      let sources_data = [];
      let sorted_identity_duplicates_sources = [];
      //for loop for checking selected source with data and pushing
      if (this.selected_tat !== "FAM" && this.selected_tat !== "LAM") {
        for (let i = 0; i < this.get_selected_data.length; i++) {
          const element = this.get_selected_data[i];
          for (let i = 0; i < element.sources.length; i++) {
            const item = element.sources[i];
            if (source === item.name) {
              sources_data.push(element);
            }
          }
        }
      }
      if (this.selected_tat === "FAM") {
        for (let i = 0; i < this.get_selected_data.length; i++) {
          const element = this.get_selected_data[i];
          const sorted_sources_time = _.sortBy(element.sources, "time");
          if (source === sorted_sources_time[0].name) {
            sources_data.push(element);
          }
        }
        // this.source_count = sources_data.length;
      }
      if (this.selected_tat === "LAM") {
        for (let i = 0; i < this.get_selected_data.length; i++) {
          const element = this.get_selected_data[i];
          const sorted_sources_time = _.sortBy(element.sources, "time");
          let last_element = sorted_sources_time.length - 1;
          if (source === sorted_sources_time[last_element].name) {
            sources_data.push(element);
          }
        }
        // this.source_count = sources_data.length;
      }
      // for each loop removing duplicates in sources_data array
      var unique = [];
      sources_data.forEach((element) => {
        // console.log("sourceStats", element )
        if (!unique[element.identity]) {
          sorted_identity_duplicates_sources.push(element);
          unique[element.identity] = 1;
        }
      });
      this.get_selected_data = sorted_identity_duplicates_sources;
    },
    getYears() {
      let current_year = new Date().getFullYear();
      console.log("current year", current_year);
      for (let i = current_year; i >= 2011; i--) {
        this.years.push(i);
      }
      console.log(this.years, "years list");
    },
    customFormatter(enrollment_date) {
      var timestamp = moment.unix(enrollment_date);
      return timestamp.format("DD-MMM-YYYY");
    },
    getMonthNumber(month) {
      this.selected_month_number = String(
        [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].indexOf(month) + 1
      );
      this.selected_month_number = parseInt(this.selected_month_number);
      return this.selected_month_number;
    },
    sortSourcesTime(data) {
      const sorted_sources_time = _.sortBy(data, "time");
      return sorted_sources_time;
    },
    getEnrollmentSourceReports() {
      this.$vs.loading();
      this.visible = false;
      if (this.selected_month_number == null) {
        this.selected_month_number = this.getMonthNumber(this.selected_month);
      }
      this.get_selected_data = [];
      let url = `${constants.MILES_CM_BACK}getEnrollmentSourceReports?year=${this.selected_year}&month=${this.selected_month_number}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          console.log("year data", response);

          if (response.data.status === "success") {
            // this.enrollment_source = JSON.parse(
            //   JSON.stringify(response.data.enrollment_data)
            // );
            this.get_selected_data = response.data.enrollment_data;
            if (this.selected_tat !== "All") {
              this.getLAMandFAMData(response.data.enrollment_data);
            }
            if (this.selected_course !== "All") {
              this.getTatBasedOnCourse(this.selected_course);
            }
            if (this.selected_source !== "All") {
              this.getTatBasedOnSource(this.selected_source);
            }
            if (this.get_fam_data === true) {
              console.log(this.fam_length, "fam_length");
              this.getRequiredFAMData(this.fam_length);
            }
            if (this.get_lam_data === true) {
              this.getRequiredLAMData(this.lam_length);
            }
            this.getUniqueCourses(response.data.enrollment_data);
            if (this.selected_tat === "All") {
              this.getUniqueSources(response.data.enrollment_data);
            }
            this.visible = true;
          }
          if (response.data.status === "error") {
            this.show_bar_chart = false;
            this.$vs.notify({
              title: response.data.message,
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getLAMandFAMData(val) {
      let selected_sources_FAM_list = [];
      let selected_sources_LAM_list = [];
      let LAM_sources_list = [];
      let FAM_sources_list = [];
      let sorted_FAM_sources_list = [];
      let sorted_LAM_sources_list = [];
      if (this.selected_tat === "FAM") {
        for (let i = 0; i < val.length; i++) {
          const element = val[i];
          //To get FAM Source,
          const sorted_sources_time = _.sortBy(element.sources, "time");
          // element.FAM_source = sorted_sources_time[0].name;

          // To get FAM sources list
          if (
            sorted_sources_time[0].name !== null &&
            sorted_sources_time[0].name !== ""
          ) {
            FAM_sources_list.push(sorted_sources_time[0].name);
          }
          // To get FAM sources count
          if (this.selected_source !== "All") {
            if (this.selected_source === sorted_sources_time[0].name) {
              selected_sources_FAM_list.push(sorted_sources_time);
            }
          }
          if (this.selected_source === "All") {
            selected_sources_FAM_list.push(sorted_sources_time);
          }
        }
        let sources_array = [];
        let get_array = [];
        for (let i = 0; i < selected_sources_FAM_list.length; i++) {
          const element = selected_sources_FAM_list[i];
          for (let i = 0; i < element.length; i++) {
            sources_array = element.map(function (item) {
              return item["name"];
            });
          }
          get_array.push(sources_array);
        }
        this.extractSourcesData(get_array);
        //To get FAM unique sources list
        var unique = [];
        FAM_sources_list.forEach((element) => {
          if (!unique[element]) {
            sorted_FAM_sources_list.push(element);
            unique[element] = 1;
          }
        });
        this.sorted_sources = this.sortArrayAlphabetically(
          sorted_FAM_sources_list
        );
      }

      if (this.selected_tat === "LAM") {
        for (let i = 0; i < val.length; i++) {
          const element = val[i];
          const sorted_sources_time = _.sortBy(element.sources, "time");
          let last_element = sorted_sources_time.length - 1;
          if (
            sorted_sources_time[last_element].name !== null &&
            sorted_sources_time[last_element].name !== ""
          ) {
            LAM_sources_list.push(sorted_sources_time[last_element].name);
          }
          // To get LAM sources count
          if (this.selected_source !== "All") {
            if (
              this.selected_source === sorted_sources_time[last_element].name
            ) {
              selected_sources_LAM_list.push(sorted_sources_time);
            }
          }
          if (this.selected_source === "All") {
            selected_sources_LAM_list.push(sorted_sources_time);
          }
        }
        let sources_array = [];
        let get_array = [];
        for (let i = 0; i < selected_sources_LAM_list.length; i++) {
          const element = selected_sources_LAM_list[i];
          for (let i = 0; i < element.length; i++) {
            sources_array = element.map(function (item) {
              return item["name"];
            });
          }
          get_array.push(sources_array);
        }
        this.extractSourcesData(get_array);
        // this.source_count = _.flatten(get_array).length;

        //To get unique Sources
        var LAM_unique = [];
        LAM_sources_list.forEach((element) => {
          if (!LAM_unique[element]) {
            sorted_LAM_sources_list.push(element);
            LAM_unique[element] = 1;
          }
        });
        this.sorted_sources = this.sortArrayAlphabetically(
          sorted_LAM_sources_list
        );
      }
      // if (this.selected_source === "All") {
      //   this.source_count = val.length;
      // }
    },
    getUniqueCourses(data) {
      console.log("data----475666", data);
      var unique = [];
      this.courses = ["All"];
      data.forEach((element) => {
        // console.log("sourceStats", element )
        if (!unique[element.course]) {
          this.courses.push(element.course);
          unique[element.course] = 1;
        }
      });
      console.log("courses!!!!!!!!!", this.courses);
    },
    getUniqueSources(data) {
      let random_array = [];
      console.log("data----", data);
      // var unique = [];
      this.sorted_sources = [];

      let get_array = [];
      let reduce_array = data.map((item) => item.sources);
      console.log("reduce_array", reduce_array);

      let sources_array = [];
      for (let i = 0; i < reduce_array.length; i++) {
        const element = reduce_array[i];
        for (let i = 0; i < element.length; i++) {
          sources_array = element.map(function (item) {
            return item["name"];
          });
        }
        get_array.push(sources_array);
      }
      if (this.selected_source !== "All" && this.selected_tat === "All") {
        for (let i = 0; i < get_array.length; i++) {
          const element = get_array[i];
          if (element.includes(this.selected_source)) {
            random_array.push(element);
          }
        }
        console.log("1random_array", random_array);
        this.extractSourcesData(random_array);
      }
      console.log("get_array", get_array);
      if (this.selected_tat === "All" && this.selected_source === "All") {
        this.extractSourcesData(get_array);
      }

      //for making array into single and removing duplicates
      this.sources = [...new Set(get_array.flat(1))];
      this.sources.push("All");
      console.log("sources", this.sources);

      for (let i = 0; i < this.sources.length; i++) {
        const element = this.sources[i];
        if (element !== null && element !== "") {
          this.sorted_sources.push(element);
        }
      }
      this.sorted_sources = this.sortArrayAlphabetically(this.sorted_sources);
      console.log("sorted_sources", this.sorted_sources);
    },
    extractSourcesData(sources_data) {
      console.log("$%^%$%", sources_data);
      if (sources_data.length === 0) {
        this.show_bar_chart = false;
      }
      let unique_sources = [];

      //To get multiple arrays into single array
      let flatten_sources_array = _.flatten(sources_data);
      this.source_count = flatten_sources_array.length;
      console.log(flatten_sources_array, "flatten_sources_array");
      //To get unique Sources and their count from sources array(flatten_sources_array)
      unique_sources = flatten_sources_array.reduce((acc, val) => {
        acc[val] = acc[val] === undefined ? 1 : (acc[val] += 1);
        return acc;
      }, {});

      console.log("unique_sources@@2", unique_sources);
      //To get descending order of Sources count
      let sorted_unique_sources = Object.keys(unique_sources)
        .sort((a, b) => (unique_sources[a] < unique_sources[b] ? 1 : -1))
        .reduce((a, b) => {
          a[b] = unique_sources[b];
          return a;
        }, {});
      console.log("sorted_unique_sources", sorted_unique_sources);
      if (this.selected_source !== "All") {
        this.source_count = sorted_unique_sources[this.selected_source];
      }
      //To get keys of sorted sources count array
      let keys = Object.keys(sorted_unique_sources);

      //To get values of sorted sources count array
      let values = Object.values(sorted_unique_sources);

      if (this.selected_tat === "All" && this.selected_source === "All") {
        this.lam_title = "LAM"
        this.getLAMgraphData(sorted_unique_sources);
        //To Fam graph Data
        this.getFAMgraphData(sorted_unique_sources);
      } else if (
        this.selected_tat === "FAM" &&
        this.selected_source === "All"
      ) {
        this.getFAMgraphData(sorted_unique_sources);
      } else if (
        this.selected_tat === "LAM" &&
        this.selected_source === "All"
      ) {
        this.lam_title = "LAM"
        this.getLAMgraphData(sorted_unique_sources);
      } else {
        this.options = {
          series: [
            {
              name: "Count",
              data: values,
            },
          ],
          chart: {
            type: "bar",
            height: 1000,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              vertical: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: keys,
          },
        };
      }
    },
    getLAMgraphData(sorted_unique_sources) {
      let LAM_sources_list = [];
      let sorted_LAM_sources_list = [];
      for (let i = 0; i < this.get_selected_data.length; i++) {
        const element = this.get_selected_data[i];
        const sorted_sources_time = _.sortBy(element.sources, "time");
        let last_element = sorted_sources_time.length - 1;
        if (
          sorted_sources_time[last_element].name !== null &&
          sorted_sources_time[last_element].name !== ""
        ) {
          LAM_sources_list.push(sorted_sources_time[last_element].name);
        }
      }
      var unique = [];
      let lam_source_keys_grpah = [];
      let lam_source_values_grpah = [];
      LAM_sources_list.forEach((element) => {
        if (!unique[element]) {
          sorted_LAM_sources_list.push(element);
          unique[element] = 1;
        }
      });
      for (let [key, value] of Object.entries(sorted_unique_sources)) {
        console.log("dgfruygtru", key, value);
        for (let i = 0; i < sorted_LAM_sources_list.length; i++) {
          if (key === sorted_LAM_sources_list[i]) {
            lam_source_keys_grpah.push(sorted_LAM_sources_list[i]);
            lam_source_values_grpah.push(
              sorted_unique_sources[sorted_LAM_sources_list[i]]
            );
          }
        }
      }

      this.options = {
        series: [
          {
            name: "Count",
            data: lam_source_values_grpah,
          },
        ],
        chart: {
          type: "bar",
          height: 1000,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            vertical: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: lam_source_keys_grpah,
        },
      };
    },
    getFAMgraphData(sorted_unique_sources) {
      // this.show_fam_graph = true;
      let FAM_sources_list = [];
      let sorted_FAM_sources_list = [];
      for (let i = 0; i < this.get_selected_data.length; i++) {
        const element = this.get_selected_data[i];
        const sorted_sources_time = _.sortBy(element.sources, "time");
        if (
          sorted_sources_time[0].name !== null &&
          sorted_sources_time[0].name !== ""
        ) {
          FAM_sources_list.push(sorted_sources_time[0].name);
        }
      }
      var fam_unique = [];
      let fam_source_keys_grpah = [];
      let fam_source_values_grpah = [];
      FAM_sources_list.forEach((element) => {
        if (!fam_unique[element]) {
          sorted_FAM_sources_list.push(element);
          fam_unique[element] = 1;
        }
      });
      console.log("hghjtirte", sorted_FAM_sources_list);
      for (let [key, value] of Object.entries(sorted_unique_sources)) {
        console.log("dgfruygtru", key, value);
        for (let i = 0; i < sorted_FAM_sources_list.length; i++) {
          if (key === sorted_FAM_sources_list[i]) {
            fam_source_keys_grpah.push(sorted_FAM_sources_list[i]);
            fam_source_values_grpah.push(
              sorted_unique_sources[sorted_FAM_sources_list[i]]
            );
          }
        }
      }
      this.fam_graph_options = {
        series: [
          {
            name: "Count",
            data: fam_source_values_grpah,
          },
        ],
        chart: {
          type: "bar",
          height: 1000,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            vertical: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: fam_source_keys_grpah,
        },
      };
    },
  },
};
</script>

<style>
</style>